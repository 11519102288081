import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Adopt = () => {
    const intl = useIntl()

    const data = useStaticQuery(graphql`
        query {
            markdownRemark(frontmatter: {slug: {eq: "adopt"}}) {
                html
                frontmatter {
                    slug
                    title
                }
            }
        }
    `)

    const __html = data.markdownRemark.html

    return (
        <Layout page='adopt' titles={["adopt"]}>
            <SEO title={intl.formatMessage({ id: "ADOPT.TITLE" })} keywords={[`Elevage`, `Cedrenchante`, `chiens`, `chats`, `chiots`, `chatons`, `berger des shetland`, `chihuahua`, `epagneul tibetain`, `british shorthair`]}/>
            <div className="container">
                <div style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html }} />
            </div>
        </Layout>
    );
};

export default Adopt;